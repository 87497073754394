"use client";

import React, { useEffect, useState } from "react";
interface LazySvgProps extends React.ComponentProps<"svg"> {
  name: string;
}
export const Icon = ({
  name,
  ...props
}: LazySvgProps) => {
  const IconComponent = require(`@/public/images/icons/${name}.svg`).default;
  const [isClient, setIsClient] = useState(false);
  const isBot = typeof window !== "undefined" ? window.isBot : true;
  useEffect(() => {
    if (!isClient && !isBot) {
      setIsClient(true);
    }
  }, []);
  if (!IconComponent) {
    return null;
  }
  const SmallIcon = require(`@/public/images/icons/svgLoader.svg`).default;
  if (!isBot && isClient) {
    return <IconComponent {...props} />;
  }
  return <SmallIcon {...props} data-sentry-element="SmallIcon" data-sentry-component="Icon" data-sentry-source-file="index.tsx" />;
};